// extracted by mini-css-extract-plugin
export var codeBoxesCol11 = "codeBox-module--code-boxes-col-1-1--2UIeZ";
export var codeBoxesCol21 = "codeBox-module--code-boxes-col-2-1--3oCQv";
export var codeBox = "codeBox-module--code-box--1ZVTT";
export var codeBoxTitle = "codeBox-module--code-box-title--JgJIG";
export var codeBoxDemo = "codeBox-module--code-box-demo--E9ZHK";
export var markdown = "codeBox-module--markdown--2hPCg";
export var codeBoxExpandTrigger = "codeBox-module--code-box-expand-trigger--3Iifz";
export var antRowRtl = "codeBox-module--ant-row-rtl--1NQde";
export var codeBoxDescription = "codeBox-module--code-box-description--2mMGu";
export var editButton = "codeBox-module--edit-button--1fX6c";
export var anticon = "codeBox-module--anticon--rabSL";
export var antRow = "codeBox-module--ant-row--WRNRQ";
export var codeBoxMeta = "codeBox-module--code-box-meta--3EBUZ";
export var expand = "codeBox-module--expand--grYd6";
export var codeExpandIcon = "codeBox-module--code-expand-icon--3NClu";
export var codeExpandIconShow = "codeBox-module--code-expand-icon-show--2NJpX";
export var codeExpandIconHide = "codeBox-module--code-expand-icon-hide--1pJPH";
export var antTooltipOpen = "codeBox-module--ant-tooltip-open---OMSp";
export var highlightWrapper = "codeBox-module--highlight-wrapper--1-eVK";
export var highlightWrapperExpand = "codeBox-module--highlight-wrapper-expand--2Wzsf";
export var highlight = "codeBox-module--highlight--3Qw29";
export var antTabsNavList = "codeBox-module--ant-tabs-nav-list--2J3np";
export var codeBoxActions = "codeBox-module--code-box-actions--3s8lu";
export var codeBoxCodeAction = "codeBox-module--code-box-code-action--3Jfiz";
export var codeBoxCodeCopy = "codeBox-module--code-box-code-copy--1FOEk";
export var anticonCheck = "codeBox-module--anticon-check--q686K";
export var codeBoxCodepen = "codeBox-module--code-box-codepen--WbY87";
export var codeBoxRiddle = "codeBox-module--code-box-riddle--ciNpn";
export var codeBoxCodesandbox = "codeBox-module--code-box-codesandbox--2os0B";
export var codeBoxDebug = "codeBox-module--code-box-debug--3FHar";
export var allCodeBoxControls = "codeBox-module--all-code-box-controls--V7Rx1";
export var componentsTooltipDemoPlacement = "codeBox-module--components-tooltip-demo-placement--DurSI";
export var componentsPopoverDemoPlacement = "codeBox-module--components-popover-demo-placement--2xjca";
export var componentsPopconfirmDemoPlacement = "codeBox-module--components-popconfirm-demo-placement--1O5Ju";